
import {directive} from 'vue-awesome-swiper';

export default {
  name: 'Tabs',
  directives: {
    swiper: directive,
  },
  props: {
    selectedInit: {
      type: Number,
      default: 0,
    },
    position: {
      type: String,
      default: 'left',
    },
  },
  data() {
    return {
      tabs: [],
      sliderOptions: {
        touchRatio: 1,
        observer: true,
        observeParents: true,
        spaceBetween: 12,
        slidesPerView: 'auto',
        centeredSlides: false,
        freeMode: true,
        watchSlidesVisibility: false,
        watchSlidesProgress: false,
        loop: false,
        loopPreventsSlide: false,
        resistance: true,
        resistanceRatio: 0.7,
        preloadImages: false,
        lazy: {
          loadOnTransitionStart: false,
          loadPrevNext: true,
        },
      },
    };
  },
  created() {
    this.tabs = this.$children;
  },
  mounted() {
    this.selectTab(this.selectedInit);
  },
  methods: {
    selectTab(index) {
      const activeTabIndex = this.tabs.findIndex(tab => tab.isActive)

      this.tabs.forEach((tab, tabIndex) => {
        tab.isActive = tabIndex === index;
      });

      if (this.tabs[index].$options.propsData.disabled) {
        this.tabs[activeTabIndex].isActive = true
        this.tabs[index].isActive = false
        this.$emit('errorChange', index);
      } else {
        this.$emit('change', index);
      }
    },
  },
};

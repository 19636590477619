
import Tabs from '~/components/elements/Tabs';
import TabItem from '~/components/elements/TabItem';
import Constructor from '~/components/catalog/constructor';

export default {
  name: 'WidgetTabs',
  components: {Constructor, TabItem, Tabs},
  props: {
    title: {
      type: String,
      default: '',
    },
    titleCenter: {
      type: Boolean,
      default: true,
    },
    position: {
      type: String,
      default: 'center',
    },
    items: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      activeTab: 0,
    }
  },
  methods: {
    onTabChange(value) {
      this.activeTab = value
    },
  },
}
